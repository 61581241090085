export const publicInformations = {
    'setiap-saat': {
        title: 'Informasi Publik yang Wajib Tersedia Setiap Saat', slug: 'setiap-saat', links: [
            {
                title: 'Maklumat pelayanan Mitras DUDI',
                description: "Maklumat pelayanan Mitras DUDI",
                link: `https://asset.mitrasdudi.id/content/public-information/setiap-saat/0091-Maklumat pelayanan Mitras DUDI.pdf`
            },
            {
                title: 'Maklumat Pelayanan',
                description: "Maklumat Pelayanan",
                link: `https://asset.mitrasdudi.id/content/public-information/setiap-saat/Maklumat Pelayanan.png`
            },
            {
                title: 'Prosedur Operasional Standar',
                description: "Prosedur Operasional Standar",
                link: `http://ringkas.kemdikbud.go.id/POSMITRASDUDI`
            },
            // {
            //     title: 'Panduan Survei Pengukuran Kinerja Kemitraan - Satuan Pendidikan Vokasi PPID',
            //     description: "Panduan Pengisian Survei Pengukuran Kinerja Kemitraan Satuan Pendidikan Vokasi dengan Dunia Usaha dan Dunia Industri Tahun 2022",
            //     link: `${process.env.REACT_APP_BASE_URL_WEB}/files/setiap-saat/Panduan Survei Pengukuran Kinerja Kemitraan - Satuan Pendidikan Vokasi PPID.pdf`
            // },
            // {
            //     title: 'Panduan Survei Pengukuran Kinerja Kemitraan dengan Satuan Pendidikan Vokasi - Mitra DUDI PPID',
            //     description: "Panduan Pengisian Survei Pengukuran Kinerja Kemitraan Satuan Pendidikan Vokasi dengan Dunia Usaha dan Dunia Industri Tahun 2022",
            //     link: `${process.env.REACT_APP_BASE_URL_WEB}/files/setiap-saat/Panduan Survei Pengukuran Kinerja Kemitraan - Mitra DUDI PPID.pdf`
            // },
            {
                title: 'Skema PTV dengan lembar pengesahan',
                description: "Skema PTV dengan lembar pengesahan",
                links: [
                    { "name": "SUPERVISOR PERAWATAN DAN REPARASI MESIN PERKAKAS.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/1. SUPERVISOR PERAWATAN DAN REPARASI MESIN PERKAKAS.pdf" },
                    { "name": "EGINEER PEMELIHARAAN DAN PERBAIKAN MOLD.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/10. EGINEER PEMELIHARAAN DAN PERBAIKAN MOLD.pdf" },
                    { "name": "Skema - Exhibition Planner.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/100. Skema - Exhibition Planner.pdf" },
                    { "name": "Skema -  Jasa Impesariat Promotor.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/101. Skema -  Jasa Impesariat Promotor.pdf" },
                    { "name": "Skema - Credit Analyst..pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/102. Skema - Credit Analyst..pdf" },
                    { "name": "SKEMA OKUPASI - FOTOGRAFER IKLAN.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/103. SKEMA OKUPASI - FOTOGRAFER IKLAN.pdf" },
                    { "name": "Skema - Manajer Produksi Film.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/104. Skema - Manajer Produksi Film.pdf" },
                    { "name": "Skema - Penyiar Variety Show.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/105. Skema - Penyiar Variety Show.pdf" },
                    { "name": "Skema - Penulis Naskah Program Siaran Televisi.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/106. Skema - Penulis Naskah Program Siaran Televisi.pdf" },
                    { "name": "Inspektor Keamanan Pangan- All. docx.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/107. Inspektor Keamanan Pangan- All. docx.pdf" },
                    { "name": "Skema - Okupasi Junior Cyber Security.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/108.Skema - Okupasi Junior Cyber Security.pdf" },
                    { "name": "SKEMA OKUPASI - ACCOUNT EXECUTIVE.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/109. SKEMA OKUPASI - ACCOUNT EXECUTIVE.pdf" },
                    { "name": "ENGINEER PENGELOLAAN PRODUKSI PLASTIK INJEKSI.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/11. ENGINEER PENGELOLAAN PRODUKSI PLASTIK INJEKSI.pdf" },
                    { "name": "kema - Pemandu Wisata Gunung.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/110. kema - Pemandu Wisata Gunung.pdf" },
                    { "name": "Skema - Pengelola Arsip Aktif Mahir.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/111. Skema - Pengelola Arsip Aktif Mahir.pdf" },
                    { "name": "Skema - Personil K3.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/112. Skema - Personil K3.pdf" },
                    { "name": "Skema Okupasi paravet Penjaminan Keamanan Produk Hewan.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/114. Skema Okupasi paravet Penjaminan Keamanan Produk Hewan.pdf" },
                    { "name": "Skema Okupasi paravet Pengendalian Penyakit Hewan.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/115. Skema Okupasi paravet Pengendalian Penyakit Hewan.pdf" },
                    { "name": "Skema Okupasi paravet Pemeriksaan dan Pengujian Laboratorium.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/116. Skema Okupasi paravet Pemeriksaan dan Pengujian Laboratorium.pdf" },
                    { "name": "Pengobat Tradisional Ramuan Pratama.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/117. Pengobat Tradisional Ramuan Pratama.pdf" },
                    { "name": "PENGOBAT TRADISIONAL RAMUAN MADYA.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/118. PENGOBAT TRADISIONAL RAMUAN MADYA.pdf" },
                    { "name": "Pengobat Tradisional Ramuan Utama.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/119. Pengobat Tradisional Ramuan Utama.pdf" },
                    { "name": "ANALIS PERANCANG MOLD.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/12. ANALIS PERANCANG MOLD.pdf" },
                    { "name": "Skema Pelayanan Laboratorium Klinik.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/120. Skema Pelayanan Laboratorium Klinik.pdf" },
                    { "name": "SKEMA PEMBUATAN ALAT ORTODONSI LEPASAN.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/121.SKEMA PEMBUATAN ALAT ORTODONSI LEPASAN.pdf" },
                    { "name": "Skema okupasi Ahli Muda Higiene Industri.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/122. Skema okupasi Ahli Muda Higiene Industri.pdf" },
                    { "name": "Skema Administrator K3.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/123. Skema Administrator K3.pdf" },
                    { "name": "SKEMA klaster Pelaksanaan Fisioterapi Perbaikan Jalan Nafas dan Ventilasi.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/125. SKEMA klaster Pelaksanaan Fisioterapi Perbaikan Jalan Nafas dan Ventilasi.pdf" },
                    { "name": "SKEMA KOMPETENSI Keperawatan Pemenuhan Kebutuhan Dasar.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/126. SKEMA KOMPETENSI Keperawatan Pemenuhan Kebutuhan Dasar.pdf" },
                    { "name": "skema RADIOGRAFER MAHIR CT-SCAN TINGKAT 1.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/127. skema RADIOGRAFER MAHIR CT-SCAN TINGKAT 1.pdf" },
                    { "name": "skema radiografer Terampil Tingkat 1.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/128. skema radiografer Terampil Tingkat 1.pdf" },
                    { "name": "Skema Sertifikasi Okupasi Fotografer Budaya dan Wisata.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/129. Skema Sertifikasi Okupasi Fotografer Budaya dan Wisata.pdf" },
                    { "name": "ANALIS MATERIAL PLASTIK.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/13. ANALIS MATERIAL PLASTIK.pdf" },
                    { "name": "Skema Lead 3D Animator.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/130. Skema Lead 3D Animator.pdf" },
                    { "name": "Skema Desainer Grafis 2.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/131. Skema Desainer Grafis 2.pdf" },
                    { "name": "Skema Desainer Grafis.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/131. Skema Desainer Grafis.pdf" },
                    { "name": "Skema sertifikasi Okupasi Digital Games Developer.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/132. Skema sertifikasi Okupasi Digital Games Developer.pdf" },
                    { "name": "Skema sertifikasi okupasi Penata Mode Busana.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/133. Skema sertifikasi okupasi Penata Mode Busana.pdf" },
                    { "name": "Skema Fotografer Utama.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/134. Skema Fotografer Utama.pdf" },
                    { "name": "Skema Advertising Executive.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/135. Skema Advertising Executive.pdf" },
                    { "name": "Skema Inovatif Produk Makanan Baru.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/136. Skema Inovatif Produk Makanan Baru.pdf" },
                    { "name": "Skema Konsultan Pengguna Kemasan Produk IKM (Industri Kecil Menengah).pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/137.  Skema Konsultan Pengguna Kemasan Produk IKM (Industri Kecil Menengah).pdf" },
                    { "name": "Skema Pengarah Seni Digital (Digital Art Director).pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/138. Skema Pengarah Seni Digital (Digital Art Director).pdf" },
                    { "name": "Skema Fasilitator Pemberdayaan Masyarakat.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/139. Skema Fasilitator Pemberdayaan Masyarakat.pdf" },
                    { "name": "KLASTER PENERAPAN SISTEM MANAJEMEN LINGKUNGAN.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/14. KLASTER PENERAPAN SISTEM MANAJEMEN LINGKUNGAN.pdf" },
                    { "name": "Skema Junior Web Programmer.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/140. Skema Junior Web Programmer.pdf" },
                    { "name": "Lembar Pengesahan dan Skema Sertifikasi Okupasi Web Designer.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/141. Lembar Pengesahan dan Skema Sertifikasi Okupasi Web Designer.pdf" },
                    { "name": "Lembar Pengesahan dan Skema Sertifikasi Okupasi Perancang Permainan Interaktif.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/142. Lembar Pengesahan dan Skema Sertifikasi Okupasi Perancang Permainan Interaktif.pdf" },
                    { "name": "Skema sertifikasi okupasi digital marketing.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/143. Skema sertifikasi okupasi digital marketing.pdf" },
                    { "name": "Skema Bahasa English Industri Kreatif.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/144. Skema Bahasa English Industri Kreatif.pdf" },
                    { "name": "ANGGOTA TIM PENYUSUN AMDAL.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/15. ANGGOTA TIM PENYUSUN AMDAL.pdf" },
                    { "name": "SUPERVISOR K3 KONSTRUKSI.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/16. SUPERVISOR K3 KONSTRUKSI.pdf" },
                    { "name": "PERSONIL KESELAMATAN DAN KESEHATAN KERJA.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/17. PERSONIL KESELAMATAN DAN KESEHATAN KERJA.pdf" },
                    { "name": "OKUPASI AHLI SISTEM MANAJEMEN MUTU KONSTRUKSI.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/18. OKUPASI AHLI SISTEM MANAJEMEN MUTU KONSTRUKSI.pdf" },
                    { "name": "AHLI GEOFISIKA PANAS BUMI.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/19. AHLI GEOFISIKA PANAS BUMI.pdf" },
                    { "name": "SUPERVISOR PERENCANAAN PRODUKSI DAN PENGENDALIAN PERSEDIAAN.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/2. SUPERVISOR PERENCANAAN PRODUKSI DAN PENGENDALIAN PERSEDIAAN.pdf" },
                    { "name": "ANALIS SISTEM INFORMASI GEOGRAFIS.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/20. ANALIS SISTEM INFORMASI GEOGRAFIS.pdf" },
                    { "name": "TEKNISI SURVEI TERESTRIS.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/21. TEKNISI SURVEI TERESTRIS.pdf" },
                    { "name": "PELAKSANA LAPANGAN PEKERJAAN GEDUNG.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/22. PELAKSANA LAPANGAN PEKERJAAN GEDUNG.pdf" },
                    { "name": "PELAKSANA PEMELIHARAAN JALAN.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/24. PELAKSANA PEMELIHARAAN JALAN.pdf" },
                    { "name": "AHLI MUDA QUANTITY SURVEYOR.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/25. AHLI MUDA QUANTITY SURVEYOR.pdf" },
                    { "name": "SKEMA ASISTEN MANAJER PEMB DTL.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/26. SKEMA ASISTEN MANAJER PEMB DTL.pdf" },
                    { "name": "ASISTEN MANAJER PEMBANGUNAN DAN PEMASANGAN DISTRIBUSI TENAGA LISTRIK TEGANGAN RENDAH.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/27. ASISTEN MANAJER PEMBANGUNAN DAN PEMASANGAN DISTRIBUSI TENAGA LISTRIK TEGANGAN RENDAH.pdf" },
                    { "name": "TEKNISI MADYA INSTALASI FIBER OPTIK.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/28. TEKNISI MADYA INSTALASI FIBER OPTIK.pdf" },
                    { "name": "WELDING INSPECTOR COMPREHENSIVE.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/29. WELDING INSPECTOR COMPREHENSIVE.pdf" },
                    { "name": "SUPERVISOR SALES TECHNICAL SUPPORT.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/3. SUPERVISOR SALES TECHNICAL SUPPORT.pdf" },
                    { "name": "WELDING INSPECTOR COMPREHENSIVE.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/30. WELDING INSPECTOR COMPREHENSIVE.pdf" },
                    { "name": "WELDING SUPERVISOR.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/31. WELDING SUPERVISOR.pdf" },
                    { "name": "WELDING SUPERINTENDENT.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/32. WELDING SUPERINTENDENT.pdf" },
                    { "name": "WELDING ENGINEER.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/33. WELDING ENGINEER.pdf" },
                    { "name": "ASISTEN MANAJER JARINGAN DISTRIBUSI TENAGA LISTRIK.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/34. ASISTEN MANAJER JARINGAN DISTRIBUSI TENAGA LISTRIK.pdf" },
                    { "name": "Skema Okupasi Asisten Manajer Pemeriksaan dan Pengujian Pemanfaatan Tegangan Rendah all.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/35. Skema Okupasi Asisten Manajer Pemeriksaan dan Pengujian Pemanfaatan Tegangan Rendah all.pdf" },
                    { "name": "Skema Okupasi Manajer Riksa UJi Pemanfaatan Tegangan rendah.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/36. Skema Okupasi Manajer Riksa UJi Pemanfaatan Tegangan rendah.pdf" },
                    { "name": "MANAJER AREA PENGATUR DISTRIBUSI.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/37. MANAJER AREA PENGATUR DISTRIBUSI.pdf" },
                    { "name": "AHLI KESELAMATAN DAN KESEHATAN KERJA.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/38. AHLI KESELAMATAN DAN KESEHATAN KERJA.pdf" },
                    { "name": "TEKNISI KESELAMATAN DAN KESEHATAN KERJA.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/39. TEKNISI KESELAMATAN DAN KESEHATAN KERJA.pdf" },
                    { "name": "SUPERVISOR PRODUKSI.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/4. SUPERVISOR PRODUKSI.pdf" },
                    { "name": "TEKNISI SENIOR REFRIGERASI KOMERSIAL DAN INDUSTRI.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/40. TEKNISI SENIOR REFRIGERASI KOMERSIAL DAN INDUSTRI.pdf" },
                    { "name": "TEKNISI SENIOR TATA UDARA KOMERSIAL DAN SISTEM VRF.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/41. TEKNISI SENIOR TATA UDARA KOMERSIAL DAN SISTEM VRF.pdf" },
                    { "name": "TEKNISI SENIOR TATA UDARA SENTRAL DAN CHILLER.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/42. TEKNISI SENIOR TATA UDARA SENTRAL DAN CHILLER.pdf" },
                    { "name": "SERVICE ADVISOR.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/43. SERVICE ADVISOR.pdf" },
                    { "name": "TECHNICIAN LEADER.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/44. TECHNICIAN LEADER.pdf" },
                    { "name": "WORKSHOP SUPERVISOR.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/45. WORKSHOP SUPERVISOR.pdf" },
                    { "name": "WORKSHOP INSTRUKTUR.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/46. WORKSHOP INSTRUKTUR.pdf" },
                    { "name": "ASSISTANT ENGINEER.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/47. ASSISTANT ENGINEER.pdf" },
                    { "name": "ENGINEER.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/48. ENGINEER.pdf" },
                    { "name": "Skema KKNI Sertifikat V Food _ Beverage All.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/49. Skema KKNI Sertifikat V Food _ Beverage All.pdf" },
                    { "name": "SUPERVISOR DESIGNER REKAYASA ALAT MEKANIK..pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/5. SUPERVISOR DESIGNER REKAYASA ALAT MEKANIK..pdf" },
                    { "name": "Skema KKNI Sertifikat VI Food _ Beverage all.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/50. Skema KKNI Sertifikat VI Food _ Beverage all.pdf" },
                    { "name": "Skema KKNI Sertifikat V Front Office.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/51. Skema KKNI Sertifikat V Front Office.pdf" },
                    { "name": "Skema KKNI Sertifikat VI Front Office.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/52. Skema KKNI Sertifikat VI Front Office.pdf" },
                    { "name": "Skema KKNI Sertifikat V Food Production.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/53. Skema KKNI Sertifikat V Food Production.pdf" },
                    { "name": "Skema KKNI Sertifikat VI Food Production.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/54. Skema KKNI Sertifikat VI Food Production.pdf" },
                    { "name": "Skema KKNI Sertifikat V Housekeeping All.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/55. Skema KKNI Sertifikat V Housekeeping All.pdf" },
                    { "name": "Skema KKNI Sertifikat VI Housekeeping.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/56.Skema KKNI Sertifikat VI Housekeeping.pdf" },
                    { "name": "Skema KKNI Sertifikat V Tour Operation.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/57.Skema KKNI Sertifikat V Tour Operation.pdf" },
                    { "name": "Skema KKNI Sertifikat VI Tour Operation.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/58. Skema KKNI Sertifikat VI Tour Operation.pdf" },
                    { "name": "Skema Okupasi Tour Leader - All.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/59. Skema Okupasi Tour Leader - All.pdf" },
                    { "name": "MANAGER PENGEMBANGAN PRODUK.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/6. MANAGER PENGEMBANGAN PRODUK.pdf" },
                    { "name": "Skema Okupasi Pemandu Wisata..pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/60. Skema Okupasi Pemandu Wisata..pdf" },
                    { "name": "PEMANDU WISATA SELAM (DIVE GUIDE).pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/61. PEMANDU WISATA SELAM (DIVE GUIDE).pdf" },
                    { "name": "PEMANTAUAN ASPEK BIOFISIK, SOSIAL, BUDAYA DAN EKONOMI DI WILAYAH PESISIR DAN PULAU PULAU KECIL.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/62. PEMANTAUAN ASPEK BIOFISIK, SOSIAL, BUDAYA DAN EKONOMI DI WILAYAH PESISIR DAN PULAU PULAU KECIL.pdf" },
                    { "name": "MELAKUKAN KEGIATAN PERSIAPAN PRA EVENT MEETING, INCENTIVE, CONVENTION DAN EXHIBITION (MICE).pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/63. MELAKUKAN KEGIATAN PERSIAPAN PRA EVENT MEETING, INCENTIVE, CONVENTION DAN EXHIBITION (MICE).pdf" },
                    { "name": "JUNIOR ENGINEER DESAIN KONSTRUKSI MEKANIK.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/64. JUNIOR ENGINEER DESAIN KONSTRUKSI MEKANIK.pdf" },
                    { "name": "JUNIOR ENGINEERING DESAIN JIGS DAN FIXTURE.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/65. JUNIOR ENGINEERING DESAIN JIGS DAN FIXTURE.pdf" },
                    { "name": "JUNIOR ENGINEER DESAIN CETAKAN INJEKSI PLASTIK.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/66. JUNIOR ENGINEER DESAIN CETAKAN INJEKSI PLASTIK.pdf" },
                    { "name": "ENGINEER DESAIN MESIN.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/67. ENGINEER DESAIN MESIN.pdf" },
                    { "name": "ENGINEER DESAIN PERKAKAS PRESISI.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/68. ENGINEER DESAIN PERKAKAS PRESISI.pdf" },
                    { "name": "ENGINEER DESAIN PRODUK MANUFAKTUR.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/69. ENGINEER DESAIN PRODUK MANUFAKTUR.pdf" },
                    { "name": "MANAGER REKAYASA OPTIMASI.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/7. MANAGER REKAYASA OPTIMASI.pdf" },
                    { "name": "SUPERVISOR PENGECORAN LOGAM.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/70. SUPERVISOR PENGECORAN LOGAM.pdf" },
                    { "name": "SUPERVISOR POLA PENGECORAN LOGAM.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/71. SUPERVISOR POLA PENGECORAN LOGAM.pdf" },
                    { "name": "ENGINEER PERANCANGAN CORAN.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/72. ENGINEER PERANCANGAN CORAN.pdf" },
                    { "name": "TEKNISI SENIOR MEKATRONIKA.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/73. TEKNISI SENIOR MEKATRONIKA.pdf" },
                    { "name": "JUNIOR ENGINEER MEKATRONIKA.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/74. JUNIOR ENGINEER MEKATRONIKA.pdf" },
                    { "name": "TEKNISI SENIOR PEMELIHARAAN SISTEM ROBOTIK.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/75. TEKNISI SENIOR PEMELIHARAAN SISTEM ROBOTIK.pdf" },
                    { "name": "ENGINEER MEKATRONIKA.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/76. ENGINEER MEKATRONIKA.pdf" },
                    { "name": "ELECTRICAL MAINTENANCE JUNIOR ENGINEER.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/77. ELECTRICAL MAINTENANCE JUNIOR ENGINEER.pdf" },
                    { "name": "AUTOMATION MAINTENANCE JUNIOR ENGINEER.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/78. AUTOMATION MAINTENANCE JUNIOR ENGINEER.pdf" },
                    { "name": "MECHANICAL MAINTENANCE JUNIOR ENGINEER.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/79. MECHANICAL MAINTENANCE JUNIOR ENGINEER.pdf" },
                    { "name": "DESAINER CETAKAN PLASTIK INJEKSI DASAR.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/8. DESAINER CETAKAN PLASTIK INJEKSI DASAR.pdf" },
                    { "name": "DIE MAKING JUNIOR ENGINEER.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/80. DIE MAKING JUNIOR ENGINEER.pdf" },
                    { "name": "MOULD MAKING JUNIOR ENGINEER.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/81. MOULD MAKING JUNIOR ENGINEER.pdf" },
                    { "name": "JUNIOR ENGINEER PEMESINAN KONVENSIONAL.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/82. JUNIOR ENGINEER PEMESINAN KONVENSIONAL.pdf" },
                    { "name": "JUNIOR ENGINEER PEMESINAN  NC CNC.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/83. JUNIOR ENGINEER PEMESINAN  NC CNC.pdf" },
                    { "name": "JUNIOR ENGINEER FABRIKASI.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/84. JUNIOR ENGINEER FABRIKASI.pdf" },
                    { "name": "Okupasi Foreman Pengolahan Minyak Kelapa Sawit.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/85. Okupasi Foreman Pengolahan Minyak Kelapa Sawit.pdf" },
                    { "name": "Okupasi Arsitek.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/86. Okupasi Arsitek.pdf" },
                    { "name": "Okupasi Asisten Kebun Kelapa Sawit - All.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/87. Okupasi Asisten Kebun Kelapa Sawit - All.pdf" },
                    { "name": "Okupasi Personal Assisstant.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/88. Okupasi Personal Assisstant.pdf" },
                    { "name": "Okupasi Junior Web Programer.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/89. Okupasi Junior Web Programer.pdf" },
                    { "name": "ENGINEER SETING PARAMETER MESIN INJECTION MOULDING.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/9. ENGINEER SETING PARAMETER MESIN INJECTION MOULDING.pdf" },
                    { "name": "Okupasi Network Administrator Muda.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/90. Okupasi Network Administrator Muda.pdf" },
                    { "name": "Okupasi Office Manager.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/91. Okupasi Office Manager.pdf" },
                    { "name": "Okupasi teknisi akuntansi ahli - all.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/92. Okupasi teknisi akuntansi ahli - all.pdf" },
                    { "name": "Okupasi asisten POPT.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/93. Okupasi asisten POPT.pdf" },
                    { "name": "Okupasi Manajer Pemasaran.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/94. Okupasi Manajer Pemasaran.pdf" },
                    { "name": "Okupasi Teknisi Laboratorium.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/95. Okupasi Teknisi Laboratorium.pdf" },
                    { "name": "Skema nasional Okupasi Teknisi Pembesaran Ikan Catfish.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/96.  Skema nasional Okupasi Teknisi Pembesaran Ikan Catfish.pdf" },
                    { "name": "Skema nasional Okupasi Teknisi Pembesaran Ikan Bandeng.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/97. Skema nasional Okupasi Teknisi Pembesaran Ikan Bandeng.pdf" },
                    { "name": "Okupasi Pengawas Mutu (Quality Control) Pembekuan Udang.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/98. Okupasi Pengawas Mutu (Quality Control) Pembekuan Udang.pdf" },
                    { "name": "Skema - Kewirausahaan Industri.(Ver.8) docx.pdf", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Skema PTV dengan lembar pengesahan/99. Skema - Kewirausahaan Industri.(Ver.8) docx.pdf" },
                ]
            },
            {
                title: 'Standar Pelayanan Kemitraan dan Pendidikan Vokasi dengan DUDI',
                description: "Standar Pelayanan Kemitraan dan Pendidikan Vokasi dengan DUDI",
                links: [

                    { "name": "SP Kemitraan mitrasdudi dengan satuan pendidikan vokasi 16_9", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Standar Pelayanan Kemitraan dan Pendidikan Vokasi dengan DUDI/SP Kemitraan mitrasdudi dengan satuan pendidikan vokasi 16_9.pdf" },
                    { "name": "SP kemitraan pendidikan vokasi 16_9", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Standar Pelayanan Kemitraan dan Pendidikan Vokasi dengan DUDI/SP kemitraan pendidikan vokasi 16_9.pdf" },
                ]
            },
            {
                title: 'Standar Pelayanan Penyelarasan Pendidikan Vokasi',
                description: "Standar Pelayanan Penyelarasan Pendidikan Vokasi",
                links: [

                    { "name": "SP Kemitraan mitrasdudi dengan satuan pendidikan vokasi 16_9", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Standar Pelayanan Penyelarasan Pendidikan Vokasi/Standar Pelayanan_ Penyelarasan Pendidikan Vokasi dengan DUDI.pdf" },
                    { "name": "SP kemitraan pendidikan vokasi 16_9", "link": "https://asset.mitrasdudi.id/content/public-information/setiap-saat/Standar Pelayanan Penyelarasan Pendidikan Vokasi/Standar Pelayanan_ Rekomendasi Pembentukan Lembaga Sertifikasi Profesi Pihak Kesatu Sekolah Meneng.pdf" },
                ]
            },
        ]
    },
    'berkala': {
        title: 'Informasi Publik yang Wajib Disediakan dan Diumumkan Secara Berkala', slug: 'berkala', links: [
            {
                title: 'Laporan Akuntabilitas Kinerja',
                description: 'Laporan Akuntabilitas Kinerja',
                links: [
                    {
                        name: 'Laporan Akuntabilitas Kinerja Direktorat Mitras DUDI 2023',
                        link: `https://asset.mitrasdudi.id/content/public-information/berkala/Laporan Akuntabilitas Kinerja Direktorat Mitras DUDI 2023.pdf`
                    },
                    {
                        name: 'Laporan Kinerja Direktorat Mitras DUDI Tahun 2022',
                        link: `${process.env.REACT_APP_BASE_URL_WEB}/files/berkala/LAKIN DIT. MITRAS DUDI.pdf`
                    },
                ]
            },
            {
                title: 'Demografi Pegawai Direktorat Mitras DUDI',
                description: "Demografi Pegawai Direktorat Mitras DUDI",
                link: `https://asset.mitrasdudi.id/content/public-information/berkala/Demografi Pegawai Direktorat Mitras DUDI.png`
            },
            {
                title: 'Perjanjian Kinerja Mitras DUDI 2024',
                description: "Perjanjian Kinerja Mitras DUDI 2024",
                link: `https://asset.mitrasdudi.id/content/public-information/berkala/Perjanjian Kinerja Mitras DUDI 2024.pdf`
            },
            {
                title: 'Persuratan Direktorat Mitras DUDI 2023',
                description: "Persuratan Direktorat Mitras DUDI 2023",
                link: `https://asset.mitrasdudi.id/content/public-information/berkala/Persuratan Direktorat Mitras DUDI 2023.png`
            },
            {
                title: 'Perjanjian Kinerja Direktorat Mitras DUDI',
                description: "Dalam rangka mewujudkan kinerja pemerintahan yang efektif, transparan, dan akuntabel serta berorientasi pada hasil, kami yang bertandatangan di bawah ini",
                link: `${process.env.REACT_APP_BASE_URL_WEB}/files/berkala/Perjanjian kinerja 2023.pdf`
            },
            {
                title: 'Rencana Aksi Direktorat Mitras DUDI Tahun 2023',
                description: "Rencana Aksi DIREKTORAT KEMITRAAN DAN PENYELARASAN DUNIA USAHA DAN DUNIA INDUSTRI Tahun 2023",
                link: `${process.env.REACT_APP_BASE_URL_WEB}/files/berkala/Rencana Aksi Perjanjian Kinerja tahun 2023.pdf`
            },
            {
                title: 'Renstra Ditjen Diksi Tahun 2020-2024 Edisi Revisi',
                description: "Rencana Strategis (Renstra) Direktorat Jenderal Pendidikan Vokasi (Ditjen Pendidikan Vokasi) tahun 2020-2024 disusun berdasarkan Nawacita, UU Nomor 20 Tahun 2003 tentang Sistem Pendidikan Nasional, Peraturan Presiden Nomor 18  Tahun 2020 tentang Rencana Pembangunan Jangka Menengah Nasional (RPJMN) Tahun  2020--2024.",
                link: `${process.env.REACT_APP_BASE_URL_WEB}/files/berkala/renstra Ditjen Pendidikan Vokasi 2020-2024 edisi revisi - edit (2).pdf`
            },
            {
                title: 'Ringkasan Laporan Keuangan Direktorat Mitras DUDI (Audited)',
                description: "Ringkasan Laporan Keuangan Direktorat Mitras DUDI",
                link: `${process.env.REACT_APP_BASE_URL_WEB}/files/berkala/Ringkasan Laporan Keuangan Direktorat Mitras DUDI (Audited).pdf`
            },
        ]
    },
    'serta-merta': {
        title: 'Informasi Publik yang Wajib Diumumkan Secara Serta Merta', slug: 'serta-merta', links: [
            {
                title: '',
                description: "",
                link: `${process.env.REACT_APP_BASE_URL_WEB}/files/setiap-saat/`
            },
        ]
    },
}