import Icon from "Common/UI/Component/Icon";
import { PaddedContainer } from "Common/UI/Layout/Container";

export default function Page() {
    return (
        <PaddedContainer className="min-h-screen bg-white">
            <div className="flex h-screen items-center justify-center">
                <section className="h-4/5">
                    <section className="flex flex-col items-center mb-32">
                        <div className="border-b-2 w-6 border-primary"></div>
                        <h2 className="text-4xl tracking-tight font-extrabold text-primary sm:text-5xl md:text-6xl">
                            Layanan
                        </h2>
                    </section>

                    <nav className="flex gap-36 justify-center">
                        <a href="https://mitreka.kemdikbud.go.id/" target="_blank" className="flex flex-col items-center hover:animate-pulse" rel="noreferrer">
                            <div className="bg-[#1c2855] rounded-full items-center justify-center flex">
                                <Icon.IconPhospor.Handshake className="h-52 w-auto text-white m-3" />
                            </div>
                            <h2 className="text-4xl tracking-tight font-extrabold sm:text-5xl md:text-6xl text-[#1c2855] ">
                                Kemitraan
                            </h2>
                            <div className="border-b-2 w-6  border-[#1c2855]"></div>
                        </a>
                        <a href="https://penyelarasan.mitrasdudi.id/" target="_blank" className="flex flex-col items-center hover:animate-pulse" rel="noreferrer">
                            <div className="bg-[#219ba8] rounded-full items-center justify-center flex">
                                <Icon.IconPhospor.GearSix className="h-52 w-auto text-white m-3" />
                            </div>
                            <h2 className="text-4xl tracking-tight font-extrabold  sm:text-5xl md:text-6xl text-[#219ba8]">
                                Penyelarasan
                            </h2>
                            <div className="border-b-2 w-6  border-[#219ba8]"></div>
                        </a>
                        <a href="https://tracervokasi.kemdikbud.go.id/" target="_blank" className="flex flex-col items-center hover:animate-pulse" rel="noreferrer">
                            <div className="bg-[#1c2855] rounded-full items-center justify-center flex">
                                <Icon.IconPhospor.Handshake className="h-52 w-auto text-white m-3" />
                            </div>
                            <h2 className="text-4xl tracking-tight font-extrabold sm:text-5xl md:text-6xl text-[#1c2855] ">
                                Penelusuran Lulusan
                            </h2>
                            <div className="border-b-2 w-6  border-[#1c2855]"></div>
                        </a>
                    </nav>
                </section>
            </div>
        </PaddedContainer>
    )
}