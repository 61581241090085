import { ArrowCircleLeft } from "@phosphor-icons/react";
import { publicInformations } from "Asset/Dummy/public-information";
import Button from "Common/UI/Component/Button";
import Icon from "Common/UI/Component/Icon";
import { PaddedContainer } from "Common/UI/Layout/Container";
import { useState } from "react";
import { useEffect } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";

export default function Page() {
    const { slug } = useParams()
    const navigate = useNavigate()
    const [subLinks, setSubLinks] = useState({ name: '', links: [] })

    const detail = (publicInformations[slug])

    useEffect(() => {
        if (!detail) {
            navigate('/not-found')
        }
    })
    return (
        <PaddedContainer className="min-h-screen bg-white">
            <div className="flex flex-col min-h-screen items-start my-20 gap-16 justify-center">

                <section className="max-w-7xl mx-auto relative px-10">
                    <div>
                        <Link to={'/informasi-publik'} className="flex flex-row items-center gap-1.5 mb-3">
                            <ArrowCircleLeft className="w-auto h-10" /> <span className="text-xl tracking-tight font-thin text-primary sm:text-2xl md:text-3xl">Kembali</span>
                        </Link>
                    </div>
                    <div className="border-b-2 w-6 border-primary"></div>
                    <h2 className="text-4xl tracking-tight font-extrabold text-primary sm:text-5xl md:text-6xl">
                        {detail.title}
                    </h2>
                    <nav className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-4 gap-y-10 my-20">
                        {detail.links.map((link, index) => (
                            <a key={index} href={link.link} onClick={() => link.link ? null : setSubLinks({ name: link.title === subLinks.name ? "" : link.title, links: link.title === subLinks.name ? [] : link.links })} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                                <div className="w-full flex items-center justify-between p-6 space-x-6">
                                    <div className="flex-1 ">
                                        <div className="flex items-center space-x-3">
                                            <h3 className="text-gray-900 text-sm font-medium ">{link.title}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="-mt-px flex divide-x divide-gray-200">
                                        <div className="w-0 flex-1 flex m-3">
                                            <Button.LoadableButton variant={link.link ? 'secondary' : (subLinks.name === link.title ? 'primary' : 'secondary')} color={'green'} icon={link.link ? Icon.IconPhospor.Download : (subLinks.name === link.title ? Icon.IconPhospor.FolderOpen : Icon.IconPhospor.Folder)}>
                                                {link.link ? 'Unduh' :
                                                    subLinks.name === link.title ? 'Tutup' : 'Buka'
                                                }
                                            </Button.LoadableButton>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        ))}
                    </nav>

                </section>
                {subLinks.links.length <= 0 ? null :
                    <section className="max-w-7xl mx-auto relative px-10">
                        <div className="border-b-2 w-6 border-primary"></div>
                        <h2 className="text-4xl tracking-tight font-extrabold text-primary sm:text-5xl md:text-6xl">
                            {subLinks.name}
                        </h2>
                        <nav className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-4 gap-y-10 my-20">
                            {subLinks.links.map((link, index) => (
                                <a key={index} href={link.link} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                                        <div className="flex-1 truncate">
                                            <div className="flex items-center space-x-3">
                                                <h3 className="text-gray-900 text-sm font-medium truncate">{link.name}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="-mt-px flex divide-x divide-gray-200">
                                            <div className="w-0 flex-1 flex m-3">
                                                <Button.LoadableButton variant="secondary" color="green" icon={Icon.IconPhospor.Download}>
                                                    Unduh
                                                </Button.LoadableButton>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            ))}
                        </nav>

                    </section>
                }
            </div>
        </PaddedContainer>
    )
}